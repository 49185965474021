import "./App.css";
import Root from "./components/Root";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ArtistDetailPage from "./components/Pages/ArtistDetailPage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import routes from "./config/routes";
import config from "./config/site";
import EventDetailPage from "./components/Pages/EventDetailPage";
import DataRenderComponent from "./components/Util/DataRenderComponent";
import { getSingleArtistInfo } from "./requests/Artists.gql";
import { getSingleEventInfo } from "./requests/Events.gql";
import { MediaPlayerProvider } from "./components/Context/MediaPlayerContext";
import { persistCache } from "apollo-cache-persist";

let domainParts = window.location.hostname.split(".");
let subdomain = domainParts.length === 3 ? domainParts[0] : null;

const artistRouter = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: (
          <DataRenderComponent
            component={ArtistDetailPage}
            gqlQuery={getSingleArtistInfo}
            pageSize={6}
            gqlVariables={{
              sort: "eventDate.startDate.desc",
            }}
          />
        ),
      },
      {
        path: "/event/:url",
        element: (
          <DataRenderComponent
            component={EventDetailPage}
            gqlQuery={getSingleEventInfo}
          />
        ),
      },
    ],
  },
]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: routes,
  },
]);

const cache = new InMemoryCache();

const initPersistor = async () => {
  await persistCache({
    cache,
    storage: window.localStorage,
  });
};

initPersistor();

const client = new ApolloClient({
  link: new HttpLink({ uri: config.backend_url + "/graphql" }),
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <link rel="preconnect" href={config.backend_url} />
      <link
        rel="preconnect"
        href={
          "https://www.google.com/recaptcha/api.js?render=" +
          config.recaptcha.site_key
        }
      />
      <MediaPlayerProvider>
        {subdomain === null && <RouterProvider router={router} />}
        {domainParts.length === 3 && <RouterProvider router={artistRouter} />}
      </MediaPlayerProvider>
    </ApolloProvider>
  );
};

export default App;
