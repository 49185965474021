const getCurrentDateISO = () => {
  let currentDate = new Date();

  currentDate.setUTCHours(0);
  currentDate.setUTCMinutes(0);
  currentDate.setUTCSeconds(0);
  currentDate.setUTCMilliseconds(0);

  return currentDate.toISOString();
};

export default {
  getCurrentDateISO: getCurrentDateISO,
};
