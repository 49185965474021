import { gql } from "@apollo/client";
import { pageMetaDataFragment } from "../components/MetaData.gql";

export const postBasicInfoFragment = gql`
  fragment postBasicInfoFragment on PostEntity {
    attributes {
      title
      url
      thumbnail {
        data {
          attributes {
            formats
            alternativeText
          }
        }
      }
      excerpt
      meta {
        ...pageMetaDataFragment
      }
    }
  }
  ${pageMetaDataFragment}
`;
