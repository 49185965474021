import { gql } from "@apollo/client";

export const bookingFormMutation = gql`
  mutation bookingFormMutation(
    $data: BookingRequestInput!
    $recaptchaToken: String!
  ) {
    createBookingRequest(data: $data, recaptchaToken: $recaptchaToken) {
      data {
        id
      }
    }
  }
`;
