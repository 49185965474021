import { gql } from "@apollo/client";
import { eventInfoFragment } from "../Events/InfoFragment.gql";

export const eventsCarouselFragment = gql`
  fragment eventsCarouselFragment on ComponentComponentsEventsCarousel {
    title
    content
    eventsData {
      ...eventInfoFragment
    }
    link {
      linkTitle
      link
    }
  }
  ${eventInfoFragment}
`;
