import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { MediaPlayerContext } from "../Context/MediaPlayerContext";
import { useQuery } from "@apollo/client";
import { getPreviousAndNextSets } from "../../requests/MusicFeedSets.gql";
import config from "../../config/site";
import { Link } from "react-router-dom";

const MediaPlayer = (props) => {
  const [isHidden, setIsHidden] = useState(false);

  const {
    id,
    title,
    createdAt,
    url,
    image,
    platform,
    playerRef,
    played,
    playedSeconds = 0,
    duration = 0,
    playing,
    loaded,
    muted,
    handleChange,
    handlePlayPause,
    handleSeekMouseDown,
    handleSeekMouseUp,
    handleProgress,
    handleDuration,
    handleChangeUrl,
  } = useContext(MediaPlayerContext);

  const { data: prevAndNextSets, loading: configLoading } = useQuery(
    getPreviousAndNextSets,
    {
      variables: {
        prevFilters: { createdAt: { lte: createdAt }, id: { lt: id } },
        prevPagination: { pageSize: 1 },
        nextFilters: { createdAt: { gte: createdAt }, id: { gt: id } },
        nextPagination: { pageSize: 1 },
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const handleSpacePress = (e) => {
    if (e.code === "Space") {
      e.preventDefault();
      handlePlayPause();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleSpacePress);

    return () => {
      document.removeEventListener("keydown", handleSpacePress);
    };
  }, []);

  const previousSet = prevAndNextSets?.previous?.data[0];
  const nextSet = prevAndNextSets?.next?.data[0];

  return (
    <>
      <div
        className={`w-full bg-white sm:h-[85px] h-[100px] ${url ? "fixed" : "hidden"} ${isHidden ? "sm:bottom-[-85px] bottom-[-100px]" : "bottom-0"} transition-all z-10 px-2 select-none`}
      >
        <div
          onClick={() => setIsHidden((prevState) => !prevState)}
          className={`absolute right-0 h-[25px] top-[-25px] px-3 secondary-font bg-gray-800 text-white cursor-pointer`}
        >
          {isHidden ? <span>Show player</span> : <span>Hide player</span>}
        </div>
        <div className={`relative`}>
          <ReactPlayer
            url={url}
            style={{ display: "none" }}
            playing={playing}
            onProgress={handleProgress}
            onDuration={handleDuration}
            volume={1}
            muted={muted}
            onEnded={
              nextSet
                ? () =>
                    handleChangeUrl(
                      nextSet?.id,
                      nextSet?.attributes?.title,
                      nextSet?.attributes?.createdAt,
                      nextSet?.attributes?.link,
                      config.backend_url +
                        nextSet?.attributes?.artwork?.data?.attributes?.formats
                          ?.thumbnail?.url,
                      nextSet?.attributes?.platform,
                      playing,
                    )
                : null
            }
            ref={playerRef}
          />
          <div className={`w-full max-w-[1140px] block mx-auto`}>
            <div className={`w-full flex leading-6 md:gap-3 gap-2 mt-2`}>
              <span className={`text-xs`}>
                {new Date(playedSeconds * 1000).toISOString().substr(11, 8)}
              </span>
              <input
                type="range"
                className={`flex-grow my-auto bg-gray-400 appearance-none h-[2px] accent-gray-800`}
                style={{
                  background: `linear-gradient(to right, rgb(55 65 81) 0%, rgb(55 65 81) ${played * 100}%, rgb(107 114 128) ${played * 100}%, rgb(107 114 128) ${loaded * 100}%, rgb(209 213 219) ${loaded * 100}%, rgb(209 213 219) 100%)`,
                }}
                value={played}
                min={0}
                max={0.999999}
                onChange={handleChange}
                onMouseDown={handleSeekMouseDown}
                onMouseUp={handleSeekMouseUp}
                onTouchStart={handleSeekMouseDown}
                onTouchEnd={handleSeekMouseUp}
                step="any"
              />

              <span className={`text-xs`}>
                {new Date(duration * 1000).toISOString().substr(11, 8)}
              </span>
            </div>
            <div className={`flex justify-between`}>
              <div className={`flex text-gray-800 my-2 max-h-[40px]`}>
                <div className={`inline-flex md:gap-3 gap-2`}>
                  <div
                    onClick={
                      previousSet
                        ? () =>
                            handleChangeUrl(
                              previousSet?.id,
                              previousSet?.attributes?.title,
                              previousSet?.attributes?.createdAt,
                              previousSet?.attributes?.link,
                              config.backend_url +
                                previousSet?.attributes?.artwork?.data
                                  ?.attributes?.formats?.thumbnail?.url,
                              previousSet?.attributes?.platform,
                              playing,
                            )
                        : null
                    }
                    className={`${previousSet ? "cursor-pointer" : "text-gray-500"}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z"
                      />
                    </svg>
                  </div>
                  <div onClick={handlePlayPause}>
                    {playing ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    onClick={
                      nextSet
                        ? () =>
                            handleChangeUrl(
                              nextSet?.id,
                              nextSet?.attributes?.title,
                              nextSet?.attributes?.createdAt,
                              nextSet?.attributes?.link,
                              config.backend_url +
                                nextSet?.attributes?.artwork?.data?.attributes
                                  ?.formats?.thumbnail?.url,
                              nextSet?.attributes?.platform,
                              playing,
                            )
                        : null
                    }
                    className={`${nextSet ? "cursor-pointer" : "text-gray-500"}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className={`secondary-font flex text-center md:mx-6 mx-2`}>
                  <img className={`max-h-full md:mr-4 mr-1`} src={image} />
                  <span
                    className={`md:py-2 md:text-base text-xs leading-[20px]`}
                  >
                    {title}
                  </span>
                </div>
              </div>
              <Link
                to={url}
                target="_blank"
                aria-label={`Open on ${platform}`}
                title={`Open on ${platform}`}
                className={`content-center flex flex-col justify-center`}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`size-6`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPlayer;
