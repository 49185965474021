import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { getGeneralSettings } from "../../requests/GeneralSettings.gql";
import React, { lazy } from "react";
import ElementAnimation from "./Utils/ElementAnimation";
import MediaPlayer from "./MediaPlayer";
import SocialLinks from "./Utils/SocialLinks";

const FooterMountains = lazy(() => import("./FooterMountains.js"));

const Footer = (props) => {
  const {
    data: configDataRaw,
    error: configError,
    loading: configLoading,
  } = useQuery(getGeneralSettings, {
    fetchPolicy: "cache-and-network",
  });

  if (configError || configLoading) {
    return <></>;
  }

  const configData = configDataRaw?.generalSettings?.data?.attributes;

  return (
    <div className={`mt-auto`}>
      <div className={`max-w-full z-0 relative`}>
        <div className={`row-start-1 col-start-1 absolute w-full z-10`}>
          <div
            className={`col-start-1 h-[50%] w-full z-10 bg-gradient-to-t from-transparent to-black flex`}
          >
            {configData && (
              <SocialLinks
                className={`pt-[30px] inline-block mx-auto`}
                data={configData}
              />
            )}
          </div>
        </div>
        <FooterMountains />
        <div className={`w-full bg-black text-white text-center`}>
          <span>© All rights reserved. | Tereda Events</span>
        </div>
        <div className={`w-full bg-black text-white text-center text-sm py-4`}>
          <span>
            <Link
              to={"/terms-and-conditions"}
              className={`underline underline-offset-1`}
            >
              Terms & Conditions
            </Link>{" "}
            |{" "}
            <Link
              to={"/privacy-policy"}
              className={`underline underline-offset-1`}
            >
              Privacy Policy
            </Link>
          </span>
        </div>
      </div>
      <MediaPlayer />
    </div>
  );
};

export default Footer;
