import { gql } from "@apollo/client";
import { artistsBasicInfoFragment } from "../Artists/InfoFragment.gql";
import { textImgSectionFragment } from "../components/TextImgSection.gql";
import { pageMetaDataFragment } from "../components/MetaData.gql";

export const eventInfoFragment = gql`
  fragment eventInfoFragment on EventEntity {
    id
    attributes {
      name
      url
      eventDate {
        id
        startDate
        endDate
      }
      location {
        id
        locationName
        locationLink
        address
        country
        region
        location
      }
      artist {
        id
        name
        country
        mainLink
        description
        links
      }
      artists {
        data {
          id
          ...artistsBasicInfoFragment
        }
      }
      ticketsLink
      coverArtwork {
        data {
          attributes {
            formats
          }
        }
      }
      labels {
        id
        title
        color
      }
      meta {
        metaKeywords
        ...pageMetaDataFragment
      }
      fbLink
      eventType
      selfHosted
      organizer
      content {
        __typename
        ...textImgSectionFragment
      }
    }
  }
  ${artistsBasicInfoFragment}
  ${textImgSectionFragment}
  ${pageMetaDataFragment}
`;
