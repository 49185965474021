import { gql } from "@apollo/client";
import { artistsShowcaseFragment } from "../components/ArtistsShowcase.gql";
import { textImgSectionFragment } from "../components/TextImgSection.gql";
import { postsCarouselFragment } from "../components/PostsCarousel.gql";
import { pageMetaDataFragment } from "../components/MetaData.gql";

export const eventsPageSettingsFragment = gql`
  fragment eventsListPage on EventsListPage {
    title
    content
    meta {
      ...pageMetaDataFragment
    }
    upcomingEventsTitle
    pastEventsTitle
    afterUpcomingEvents {
      ...textImgSectionFragment
      ...artistsShowcaseFragment
      ...postsCarouselFragment
    }
    afterPastEvents {
      ...textImgSectionFragment
      ...artistsShowcaseFragment
      ...postsCarouselFragment
    }
  }
  ${artistsShowcaseFragment}
  ${textImgSectionFragment}
  ${postsCarouselFragment}
  ${pageMetaDataFragment}
`;
