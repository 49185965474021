import config from "../../../config/site";
import React, { useContext, useState } from "react";
import { MediaPlayerContext } from "../../Context/MediaPlayerContext";
import { Link } from "react-router-dom";

const SetsList = (props) => {
  const { musicFeedData } = props;
  const { handleChangeUrl, handleForcedPlayPause } =
    useContext(MediaPlayerContext);
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (id, title, createdAt, img, platform) => {
    handleChangeUrl(id, title, createdAt, img, platform);
    setActiveItem(id);
  };

  const handlePlay = (e) => {
    e.stopPropagation();
    handleForcedPlayPause(true);
    setActiveItem(null);
  };

  const handleOpenLink = () => {
    setActiveItem(null);
  };

  return (
    <div className={`grid md:grid-cols-2 grid-cols-1`}>
      {musicFeedData &&
        musicFeedData.map((musicFeedItem) => (
          <div
            className={`grid grid-cols-7 min-h-[100px] h-full md:min-h-[150px] cursor-pointer relative`}
            key={musicFeedItem?.id}
            onClick={() =>
              handleClick(
                musicFeedItem?.id,
                musicFeedItem?.attributes?.title,
                musicFeedItem?.attributes?.createdAt,
                musicFeedItem?.attributes?.link,
                config.backend_url +
                  musicFeedItem?.attributes?.artwork?.data?.attributes?.formats
                    ?.thumbnail?.url,
                musicFeedItem?.attributes?.platform,
              )
            }
          >
            <div
              className={`flex absolute w-full h-full bg-white/70 backdrop-blur-sm ease-in-out duration-300 z-[1] ${activeItem === musicFeedItem?.id ? "opacity-100" : "opacity-0 invisible"}`}
            >
              <div
                className={`flex flex-col w-full h-full justify-center text-center`}
                onClick={handlePlay}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-12 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                  />
                </svg>
                <span className={`secondary-font`}>Play</span>
              </div>
              <div
                className={`flex flex-col w-full h-full justify-center text-center`}
                onClick={handleOpenLink}
              >
                <Link
                  onClick={(event) => event.stopPropagation()}
                  to={musicFeedItem?.attributes?.link}
                  target="_blank"
                  aria-label={`Open on ${musicFeedItem?.attributes?.platform}`}
                  title={`Open on ${musicFeedItem?.attributes?.platform}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-12 mx-auto"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </Link>
                <span className={`secondary-font`}>
                  Open on
                  <br />
                  {musicFeedItem?.attributes?.platform}
                </span>
              </div>
            </div>
            <div className={`col-span-2 relative z-0`}>
              <div
                className={`w-full h-full bg-cover bg-no-repeat bg-center`}
                style={{
                  backgroundImage: `url('${
                    config.backend_url +
                    musicFeedItem?.attributes?.artwork?.data?.attributes
                      ?.formats?.thumbnail?.url
                  }')`,
                }}
              ></div>
            </div>
            <div
              className={`flex flex-col col-span-5 px-2 bg-black text-white py-4`}
            >
              <h2 className={`secondary-font md:text-lg text-base`}>
                {musicFeedItem?.attributes?.title}
              </h2>
              <span
                className={`block secondary-font text-gray-500 md:text-lg text-base`}
              >
                By {musicFeedItem?.attributes?.madeBy}
              </span>
              <div className={`mt-auto flex justify-between`}>
                <span className={`secondary-font`}>
                  <i
                    className={`${musicFeedItem?.attributes?.platform}-icon inline-block align-middle w-[20px] h-[20px] mr-2`}
                  />
                  <span className={`relative bottom-[-1.5px]`}>
                    {musicFeedItem?.attributes?.platform}
                  </span>
                </span>
                <Link
                  onClick={(event) => event.stopPropagation()}
                  to={musicFeedItem?.attributes?.link}
                  target="_blank"
                  aria-label={`Open on ${musicFeedItem?.attributes?.platform}`}
                  title={`Open on ${musicFeedItem?.attributes?.platform}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SetsList;
