import { gql } from "@apollo/client";
import { artistsBasicInfoFragment } from "../Artists/InfoFragment.gql";

export const artistsShowcaseFragment = gql`
  fragment artistsShowcaseFragment on ComponentComponentsArtistsShowcase {
    title
    content
    artistsData {
      ...artistsBasicInfoFragment
    }
  }
  ${artistsBasicInfoFragment}
`;
