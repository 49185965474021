import Event from "../Events/Event";
import { Link } from "react-router-dom";
import config from "../../../config/site";
import Events from "../RichSnippet/Events";
import Section from "../Section";
import ElementAnimation from "../Utils/ElementAnimation";

const EventsSlider = (props) => {
  const { data } = props;

  const eventsData = data?.eventsData;

  return (
    <Section title={data?.title} contentBefore={data?.content} inverted={false}>
      <Events eventsData={eventsData} addItemList={true} />
      <div>
        {eventsData && eventsData.length > 0 && (
          <ul
            className={`flex flex-no-wrap items-stretch overflow-x-scroll scrolling-touch scrollbar-hidden md:grid lg:grid-cols-3 md:grid-cols-2 pb-4 mb-4 gap-4 ml-1`}
          >
            {eventsData.map((event, index) => (
              <li
                className={`flex-none w-3/4 md:w-full flex flex-col`}
                key={event.attributes.url}
              >
                <ElementAnimation
                  animation="fadeInUp"
                  delay={200 + index * 100}
                  className={`flex-1 auto-rows-fr min-w-full`}
                >
                  <Event
                    name={event.attributes.name}
                    image={
                      config.backend_url +
                      event?.attributes?.coverArtwork?.data?.attributes?.formats
                        ?.eventsslider?.url
                    }
                    labels={event.attributes.labels}
                    date={event.attributes.eventDate.startDate}
                    location={event.attributes.location.locationName}
                    locationLink={event.attributes.location.locationLink}
                    eventLink={event.attributes.url}
                    key={event.attributes.url}
                  ></Event>
                </ElementAnimation>
              </li>
            ))}
          </ul>
        )}
        {(!eventsData || eventsData.length === 0) && (
          <ElementAnimation animation="fadeIn" delay={300}>
            <p>There are currently no events to display.</p>
          </ElementAnimation>
        )}
        {data?.link?.link && (
          <div className={`w-full`}>
            <ElementAnimation animation="fadeIn" delay={300}>
              <Link
                className={`block text-center underline`}
                to={data?.link?.link}
              >
                <span>{data?.link?.linkTitle} ></span>
              </Link>
            </ElementAnimation>
          </div>
        )}
      </div>
    </Section>
  );
};

export default EventsSlider;
