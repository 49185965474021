import React, { createContext, useRef, useState } from "react";

export const MediaPlayerContext = createContext();

export const MediaPlayerProvider = ({ children }) => {
  const playerRef = useRef();

  const [playerState, setPlayerState] = useState({
    id: null,
    title: "",
    createdAt: null,
    url: "",
    image: "",
    platform: "",
    played: 0.0,
    playedSeconds: 0,
    playing: false,
    loaded: 0.0,
    seeking: false,
    duration: 0,
    muted: true,
  });

  const {
    id,
    title,
    createdAt,
    url,
    image,
    platform,
    played,
    playedSeconds,
    duration,
    playing,
    loaded,
    seeking,
    muted,
  } = playerState;
  const handleChange = (event) => {
    setPlayerState({ ...playerState, played: event.target.value });
  };
  const handlePlayPause = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  };

  const handleForcedPlayPause = (playState) => {
    setPlayerState((prevState) => ({
      ...prevState,
      playing: playState,
    }));
  };

  const handleSeekMouseDown = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      playing: prevState.playing,
      seeking: true,
    }));
  };

  const handleSeekMouseUp = (event) => {
    setPlayerState((prevState) => ({
      ...prevState,
      played: event.target.value,
      seeking: false,
    }));
    playerRef.current.seekTo(event.target.value, "fraction");
  };

  const handleProgress = ({ played, playedSeconds, loaded }) => {
    if (!seeking) {
      setPlayerState((prevState) => ({
        ...prevState,
        played: parseFloat(played.toFixed(5)),
        playedSeconds,
        loaded: parseFloat(loaded.toFixed(5)),
      }));
    }
  };

  const handleChangeUrl = (
    id,
    title,
    createdAt,
    url,
    image,
    platform,
    autoplay,
  ) => {
    setPlayerState({
      id: id,
      title: title,
      createdAt: createdAt,
      url: url,
      image: image,
      platform: platform,
      played: 0.0,
      playedSeconds: 0,
      playing: autoplay,
      loaded: 0.0,
      seeking: false,
      duration: 0,
      muted: true,
    });
  };

  const handleDuration = (duration) => {
    setPlayerState((prevState) => ({
      ...prevState,
      duration,
    }));
  };

  return (
    <MediaPlayerContext.Provider
      value={{
        id,
        title,
        createdAt,
        url,
        image,
        platform,
        playerRef,
        played,
        playedSeconds,
        duration,
        playing,
        loaded,
        muted,
        handleChange,
        handlePlayPause,
        handleSeekMouseDown,
        handleSeekMouseUp,
        handleProgress,
        handleChangeUrl,
        handleDuration,
        handleForcedPlayPause,
      }}
    >
      {children}
    </MediaPlayerContext.Provider>
  );
};
