import { Link } from "react-router-dom";
import ElementAnimation from "./ElementAnimation";

const LinkButton = (props) => {
  const { link, title, inverted, centered = true, targetBlank = false } = props;

  return (
    <ElementAnimation animation="fadeIn" delay={400}>
      <div className={`max-w-[540px] w-[95%] mx-auto`}>
        {link?.charAt(0) === "#" ? (
          <a
            href={link}
            target={targetBlank ? "_blank" : "_self"}
            className={props.className}
          >
            <div
              className={`${
                inverted ? "bg-white text-black" : "bg-black text-white"
              } w-[100%] ${
                centered ? "mx-auto" : ""
              } px-10 text-3xl text-center py-2`}
            >
              <span className={`m-0 secondary-font`}>{title}</span>
            </div>
          </a>
        ) : (
          <Link
            to={link}
            target={targetBlank ? "_blank" : "_self"}
            className={props.className}
          >
            <div
              className={`${
                inverted ? "bg-white text-black" : "bg-black text-white"
              } w-[100%] ${centered ? "mx-auto" : ""} text-3xl text-center py-2`}
            >
              <span className={`m-0 secondary-font`}>{title}</span>
            </div>
          </Link>
        )}
      </div>
    </ElementAnimation>
  );
};

export default LinkButton;
