import React, { lazy } from "react";
import { getPageData } from "../requests/Page.gql";
import { getEventsInfo, getSingleEventInfo } from "../requests/Events.gql";
import { getArtistPageSettings } from "../requests/Artists/PageSettingsFragment.gql";
import getCurrentDateISO from "../util/date";
import { getBlogPageSettings } from "../requests/Blog/PageSettingsFragment.gql";
import { getSingleArtistInfo } from "../requests/Artists.gql";
import { getSinglePostInfo } from "../requests/Blog.gql";
import { getMusicFeedPageSettings } from "../requests/MusicFeedSets.gql";

const HomePage = lazy(() => import("../components/Pages/HomePage"));
const EventListPage = lazy(() => import("../components/Pages/EventListPage"));
const EventDetailPage = lazy(
  () => import("../components/Pages/EventDetailPage"),
);
const ArtistListPage = lazy(() => import("../components/Pages/ArtistListPage"));
const MusicFeedPage = lazy(() => import("../components/Pages/MusicFeedPage"));
const ContactPage = lazy(() => import("../components/Pages/ContactPage"));
const ArticleListPage = lazy(
  () => import("../components/Pages/ArticleListPage"),
);
const ArticleDetailPage = lazy(
  () => import("../components/Pages/ArticleDetailPage"),
);
const SimplePage = lazy(() => import("../components/Pages/SimplePage"));
const DataRenderComponent = lazy(
  () => import("../components/Util/DataRenderComponent"),
);
const ArtistDetailPage = lazy(
  () => import("../components/Pages/ArtistDetailPage"),
);

const routes = [
  {
    path: "/",
    element: (
      <DataRenderComponent
        component={HomePage}
        gqlQuery={getPageData}
        gqlVariables={{ url: "home" }}
      />
    ),
  },
  {
    path: "/events",
    element: (
      <DataRenderComponent
        component={EventListPage}
        gqlQuery={getEventsInfo}
        pageSize={6}
        gqlVariables={{
          upcomingSort: "eventDate.startDate",
          upcomingFilters: {
            eventDate: {
              endDate: { gte: getCurrentDateISO.getCurrentDateISO() },
            },
          },
          sort: "eventDate.startDate.desc",
          filters: {
            eventDate: {
              endDate: { lte: getCurrentDateISO.getCurrentDateISO() },
            },
          },
        }}
      />
    ),
  },
  {
    path: "/events/page/:pageNr",
    element: (
      <DataRenderComponent
        component={EventListPage}
        gqlQuery={getEventsInfo}
        pageSize={6}
        gqlVariables={{
          upcomingSort: "eventDate.startDate",
          upcomingFilters: {
            eventDate: {
              endDate: { gte: getCurrentDateISO.getCurrentDateISO() },
            },
          },
          sort: "eventDate.startDate.desc",
          filters: {
            eventDate: {
              endDate: { lte: getCurrentDateISO.getCurrentDateISO() },
            },
          },
        }}
      />
    ),
  },
  {
    path: "/event/:url",
    element: (
      <DataRenderComponent
        component={EventDetailPage}
        gqlQuery={getSingleEventInfo}
      />
    ),
  },
  {
    path: "/artists",
    element: (
      <DataRenderComponent
        component={ArtistListPage}
        gqlQuery={getArtistPageSettings}
      />
    ),
  },
  {
    path: "/artist/:url",
    element: (
      <DataRenderComponent
        component={ArtistDetailPage}
        gqlQuery={getSingleArtistInfo}
        pageSize={6}
        gqlVariables={{
          sort: "eventDate.startDate.desc",
        }}
      />
    ),
  },
  {
    path: "/articles",
    element: (
      <DataRenderComponent
        component={ArticleListPage}
        gqlQuery={getBlogPageSettings}
        pageSize={6}
        gqlVariables={{
          sort: "createdAt:DESC",
        }}
      />
    ),
  },
  {
    path: "/articles/page/:pageNr",
    element: (
      <DataRenderComponent
        component={ArticleListPage}
        gqlQuery={getBlogPageSettings}
        pageSize={6}
        gqlVariables={{
          sort: "createdAt:DESC",
        }}
      />
    ),
  },
  {
    path: "/article/:url",
    element: (
      <DataRenderComponent
        component={ArticleDetailPage}
        gqlQuery={getSinglePostInfo}
        gqlVariables={{
          sort: "eventDate.startDate.desc",
        }}
      />
    ),
  },
  {
    path: "/music-feed",
    element: (
      <DataRenderComponent
        component={MusicFeedPage}
        gqlQuery={getMusicFeedPageSettings}
        pageSize={10}
        gqlVariables={{
          sort: "createdAt:DESC",
        }}
      />
    ),
  },
  {
    path: "/music-feed/page/:pageNr",
    element: (
      <DataRenderComponent
        component={MusicFeedPage}
        gqlQuery={getMusicFeedPageSettings}
        pageSize={10}
        gqlVariables={{
          sort: "createdAt:DESC",
        }}
      />
    ),
  },
  {
    path: "/contact",
    element: (
      <DataRenderComponent
        component={ContactPage}
        gqlQuery={getPageData}
        gqlVariables={{ url: "contact" }}
      />
    ),
  },
  {
    path: "/:url",
    element: (
      <DataRenderComponent component={SimplePage} gqlQuery={getPageData} />
    ),
  },
];

export default routes;
