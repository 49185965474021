import { gql } from "@apollo/client";
import { artistsShowcaseFragment } from "./components/ArtistsShowcase.gql";
import { textImgSectionFragment } from "./components/TextImgSection.gql";
import { postsCarouselFragment } from "./components/PostsCarousel.gql";
import { eventsCarouselFragment } from "./components/EventsCarousel.gql";
import { pageMetaDataFragment } from "./components/MetaData.gql";

export const getPageData = gql`
  query page($url: String!) {
    page(url: $url) {
      data {
        id
        attributes {
          title
          headContent
          meta {
            ...pageMetaDataFragment
          }
          content {
            ...textImgSectionFragment
            ...artistsShowcaseFragment
            ...postsCarouselFragment
            ...eventsCarouselFragment
          }
        }
      }
    }
  }
  ${artistsShowcaseFragment}
  ${textImgSectionFragment}
  ${postsCarouselFragment}
  ${eventsCarouselFragment}
  ${pageMetaDataFragment}
`;
