import { gql } from "@apollo/client";

export const collectionMetaDataFragment = gql`
  fragment collectionMetaDataFragment on ResponseCollectionMeta {
    pagination {
      total
      page
      pageSize
      pageCount
    }
  }
`;

export const pageMetaDataFragment = gql`
  fragment pageMetaDataFragment on ComponentMetaDataMetaData {
    metaKeywords
    metaDescription
  }
`;
