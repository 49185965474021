import { gql } from "@apollo/client";
import { artistsBasicInfoFragment } from "./InfoFragment.gql";
import { textImgSectionFragment } from "../components/TextImgSection.gql";
import { postsCarouselFragment } from "../components/PostsCarousel.gql";
import { eventsCarouselFragment } from "../components/EventsCarousel.gql";
import { pageMetaDataFragment } from "../components/MetaData.gql";

export const getArtistPageSettings = gql`
  query artistsListPage {
    artistsListPage {
      data {
        id
        attributes {
          title
          content
          afterListContent {
            ...textImgSectionFragment
            ...postsCarouselFragment
            ...eventsCarouselFragment
          }
          meta {
            ...pageMetaDataFragment
          }
        }
      }
    }
    artists {
      data {
        id
        ...artistsBasicInfoFragment
      }
    }
  }
  ${artistsBasicInfoFragment}
  ${textImgSectionFragment}
  ${postsCarouselFragment}
  ${eventsCarouselFragment}
  ${pageMetaDataFragment}
`;
