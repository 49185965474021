import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ElementAnimation from "../Utils/ElementAnimation";
import { useMutation } from "@apollo/client";
import { bookingFormMutation } from "../../../requests/mutations/BookingForm.gql";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../config/site";

const BookingForm = (props) => {
  const [sendBookingForm, { data, loading, error }] =
    useMutation(bookingFormMutation);

  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [date, setDate] = useState("");
  const [dateTouched, setDateTouched] = useState(false);
  const [location, setLocation] = useState("");
  const [locationTouched, setLocationTouched] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTouched, setMessageTouched] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [acceptTermsAndConditionsTouched, setAcceptTermsAndConditionsTouched] =
    useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [acceptPrivacyPolicyTouched, setAcceptPrivacyPolicyTouched] =
    useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const recaptchaRef = useRef();

  const { url } = useParams();

  const nameIsValid = name.trim() !== "";
  const emailIsEmpty = email.trim() === "";
  const emailIsValid =
    email.trim() !== "" &&
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    );
  const locationIsValid = location.trim() !== "";
  const messageIsValid = message.trim() !== "";
  const dateIsValid = date.trim() !== "";
  const isFormValid =
    nameIsValid &&
    !emailIsEmpty &&
    emailIsValid &&
    dateIsValid &&
    locationIsValid &&
    messageIsValid &&
    acceptTermsAndConditions &&
    acceptPrivacyPolicy;

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameTouched(true);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailTouched(true);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
    setDateTouched(true);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
    setLocationTouched(true);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setMessageTouched(true);
  };

  const handleAcceptTermsAndConditionsChange = (event) => {
    setAcceptTermsAndConditions(event.target.checked);
    setAcceptTermsAndConditionsTouched(true);
  };

  const handleAcceptPrivacyPolicy = (event) => {
    setAcceptPrivacyPolicy(event.target.checked);
    setAcceptPrivacyPolicyTouched(true);
  };

  const fieldRequiredError = (
    <span className={`text-md text-red-700 mt-2 block`}>
      This field is required!
    </span>
  );

  const generalError = (
    <span className={`text-md text-red-700 mt-2 block`}>
      Something went wrong! Please try again!
    </span>
  );

  const fieldDataInvalidError = (
    <span className={`text-md text-red-700 mt-2 block`}>Invalid value!</span>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    const token = await recaptchaRef.current.executeAsync();

    const formData = {
      name,
      location,
      artist: url,
      email,
      date,
      message,
      acceptTermsAndConditions,
      acceptPrivacyPolicy,
    };

    await sendBookingForm({
      variables: { data: formData, recaptchaToken: token },
    }).then(() => {
      setIsFormSubmitted(true);
    });
  };

  return (
    <div id="booking" className={`md:w-[70%] w-[95%] mx-auto`}>
      {!isFormSubmitted && (
        <ElementAnimation animation="fadeInUp" delay={200}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.recaptcha.site_key}
            size="invisible"
          />
          <form onSubmit={handleSubmit}>
            <input id="dj" name={"dj"} value={url} type={"hidden"} required />
            <div className={`pb-6`}>
              <label
                htmlFor="name"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Name
              </label>
              <input
                id="name"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"name"}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameChange}
                type={"text"}
                required
              />
              {nameTouched && !nameIsValid && fieldRequiredError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="email"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                E-Mail
              </label>
              <input
                id="email"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"email"}
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailChange}
                type={"text"}
                required
              />
              {emailTouched && emailIsEmpty && fieldRequiredError}
              {emailTouched &&
                !emailIsEmpty &&
                !emailIsValid &&
                fieldDataInvalidError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="date"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Date
              </label>
              <input
                id="date"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"date"}
                value={date}
                onChange={handleDateChange}
                onBlur={handleDateChange}
                type={"date"}
                required
              />
              {dateTouched && emailIsEmpty && fieldRequiredError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="location"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Location
              </label>
              <input
                id="location"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"location"}
                value={location}
                onChange={handleLocationChange}
                onBlur={handleLocationChange}
                type={"text"}
                required
              />
              {locationTouched && !locationIsValid && fieldRequiredError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="message"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Message
              </label>
              <textarea
                id="message"
                className={`w-[100%] line-he block leading-4 h-40 text-black px-3`}
                name={"message"}
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageChange}
                required
              />
              {messageTouched && !messageIsValid && fieldRequiredError}
            </div>
            <div>
              <label
                htmlFor="acceptTermsAndConditions"
                className={`secondary-font text-xl checkbox-container`}
              >
                I accept the{" "}
                <Link
                  to={"/terms-and-conditions"}
                  className={`underline underline-offset-1`}
                >
                  Terms & Conditions
                </Link>{" "}
                of this site
                <input
                  id="acceptTermsAndConditions"
                  name="acceptTermsAndConditions"
                  type="checkbox"
                  checked={acceptTermsAndConditions}
                  onChange={handleAcceptTermsAndConditionsChange}
                  required
                />
                <span className="checkmark"></span>
              </label>
              {!acceptTermsAndConditions &&
                acceptTermsAndConditionsTouched &&
                fieldRequiredError}
            </div>
            <div>
              <label
                htmlFor="acceptPrivacyPolicy"
                className={`secondary-font text-xl checkbox-container`}
              >
                I accept the{" "}
                <Link
                  to={"/privacy-policy"}
                  className={`underline underline-offset-1`}
                >
                  Privacy Policy
                </Link>{" "}
                of this site
                <input
                  className={`checkbox w-5 h-5`}
                  id="acceptPrivacyPolicy"
                  name="acceptPrivacyPolicy"
                  type="checkbox"
                  checked={acceptPrivacyPolicy}
                  onChange={handleAcceptPrivacyPolicy}
                  required
                />
                <span className="checkmark"></span>
              </label>
              {!acceptPrivacyPolicy &&
                acceptPrivacyPolicyTouched &&
                fieldRequiredError}
            </div>
            <div>
              <button
                className={`${
                  !isFormValid ? "text-gray-500" : "text-black"
                } w-[100%] bg-white sm:text-4xl text-2xl py-2 secondary-font`}
                type="submit"
                onSubmit={handleSubmit}
                disabled={!isFormValid}
              >
                Send Request
              </button>
            </div>
          </form>
        </ElementAnimation>
      )}
      {error && generalError}
      {isFormSubmitted && (
        <div className={`text-center`}>
          <span className={`secondary-font text-xl`}>
            Thanks for your request! We'll reach out to you as soon as we can!
          </span>
        </div>
      )}
    </div>
  );
};

export default BookingForm;
