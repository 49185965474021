import { Link } from "react-router-dom";
import config from "../../config/site";
import Section from "./Section";
import ElementAnimation from "./Utils/ElementAnimation";

const ArtistsShowcase = (props) => {
  const { data } = props;
  const { artistsData: artistData } = data || {};

  return (
    <>
      {data && (
        <Section
          title={data.title}
          contentBefore={data.content}
          inverted={true}
        >
          <div className={`flex flex-wrap justify-center`}>
            {artistData &&
              artistData.map((artist, index) => (
                <div
                  className={`text-center md:flex-col-5 flex-col-3 p-5 min-w-[80px]`}
                  key={artist?.attributes?.url}
                >
                  <ElementAnimation
                    animation="fadeInUp"
                    delay={200 + index * 100}
                  >
                    <Link to={`/artist/` + artist?.attributes?.url}>
                      <img
                        loading="lazy"
                        src={
                          config.backend_url +
                          artist?.attributes?.image?.data?.attributes?.formats
                            ?.xs?.url
                        }
                        alt={
                          artist?.attributes?.image?.data?.attributes
                            ?.alternativeText
                        }
                        width="177px"
                        height="177px"
                      />
                      <h3 className={`md:text-2xl text-xl secondary-font`}>
                        {artist?.attributes?.name}
                      </h3>
                    </Link>
                  </ElementAnimation>
                </div>
              ))}
          </div>
        </Section>
      )}
    </>
  );
};

export default ArtistsShowcase;
