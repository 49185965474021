import WebSite from "./RichSnippet/WebSite";
import Organization from "./RichSnippet/Organization";
import { useQuery } from "@apollo/client";
import { getGeneralSettings } from "../../requests/GeneralSettings.gql";
import { getArtistsBasicInfo } from "../../requests/Artists.gql";
import Members from "./RichSnippet/Members";

const RichSnippets = (props) => {
  const { data: contactInfoRaw } = useQuery(getGeneralSettings, {
    fetchPolicy: "cache-and-network",
  });

  const { data: artistsRaw } = useQuery(getArtistsBasicInfo, {
    fetchPolicy: "cache-and-network",
  });

  const artistsData = artistsRaw?.data;
  const contactInfo = contactInfoRaw?.data?.attributes;

  return (
    <>
      <WebSite />
      <Organization contactInfo={contactInfo} artistsData={artistsData} />
      <Members artistsData={artistsData} />
    </>
  );
};

export default RichSnippets;
