import ElementAnimation from "./ElementAnimation";
import React from "react";

const SocialLinks = (props) => {
  const { data, className } = props;

  return (
    <div className={className}>
      {data?.socialLinks?.email && (
        <ElementAnimation
          className={`inline-block`}
          animation="fadeInUp"
          delay={300}
        >
          <a
            aria-label="Tereda Email"
            target="_blank"
            className={`pr-2 ease-in-out duration-300 delay-300`}
            href={`mailto:` + data?.socialLinks?.email}
          >
            <i className={`email-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {data?.socialLinks?.facebook && (
        <ElementAnimation
          className={`inline-block`}
          animation="fadeInUp"
          delay={350}
        >
          <a
            aria-label="Tereda Facebook"
            target="_blank"
            className={`p-2`}
            href={
              !/^https?:\/\//i.test(data?.socialLinks?.facebook)
                ? `http://${data?.socialLinks?.facebook}`
                : data?.socialLinks?.facebook
            }
          >
            <i className={`fb-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {data?.socialLinks?.instagram && (
        <ElementAnimation
          className={`inline-block`}
          animation="fadeInUp"
          delay={400}
        >
          <a
            aria-label="Tereda Instagram"
            target="_blank"
            className={`p-2`}
            href={
              !/^https?:\/\//i.test(data?.socialLinks?.instagram)
                ? `http://${data?.socialLinks?.instagram}`
                : data?.socialLinks?.instagram
            }
          >
            <i className={`ig-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {data?.socialLinks?.soundcloud && (
        <ElementAnimation
          className={`inline-block`}
          animation="fadeInUp"
          delay={450}
        >
          <a
            aria-label="Tereda SoundCloud"
            target="_blank"
            className={`p-2`}
            href={
              !/^https?:\/\//i.test(data?.socialLinks?.soundcloud)
                ? `http://${data?.socialLinks?.soundcloud}`
                : data?.socialLinks?.soundcloud
            }
          >
            <i className={`soundcloud-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {data?.socialLinks?.youtube && (
        <ElementAnimation
          className={`inline-block`}
          animation="fadeInUp"
          delay={500}
        >
          <a
            aria-label="Tereda YouTube"
            target="_blank"
            className={`pl-2`}
            href={
              !/^https?:\/\//i.test(data?.socialLinks?.youtube)
                ? `http://${data?.socialLinks?.youtube}`
                : data?.socialLinks?.youtube
            }
          >
            <i className={`youtube-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
    </div>
  );
};

export default SocialLinks;
