import { gql } from "@apollo/client";

export const artistsBasicInfoFragment = gql`
  fragment artistsBasicInfoFragment on ArtistEntity {
    attributes {
      name
      image {
        data {
          attributes {
            formats
            alternativeText
          }
        }
      }
      url
      jobTitle
      residentCountry
      link {
        id
        link
        linkTitle
      }
      socialLinks {
        id
        email
        facebook
        instagram
        youtube
        soundcloud
      }
    }
  }
`;
