import jsonp from "jsonp";
import { useState } from "react";
import Section from "../Section";
import { Link } from "react-router-dom";
import ElementAnimation from "../Utils/ElementAnimation";

const defaultValues = {
  title: "Stay Updated",
  content: "Subscribe to our newsletter so you won’t miss anything!",
};

const url =
  "https://gmail.us8.list-manage.com/subscribe/post-json?u=d012ed20da0f1a7e119975e7c&amp;id=f913a6125b&amp;v_id=3355&amp;f_id=00746be0f0";

const MailChimpForm = (props) => {
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [acceptTermsAndConditionsTouched, setAcceptTermsAndConditionsTouched] =
    useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [acceptPrivacyPolicyTouched, setAcceptPrivacyPolicyTouched] =
    useState(false);

  const emailIsEmpty = email.trim() === "";

  const isFormValid =
    !emailIsEmpty && acceptTermsAndConditions && acceptPrivacyPolicy;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailTouched(true);
  };

  const handleAcceptTermsAndConditionsChange = (event) => {
    setAcceptTermsAndConditions(event.target.checked);
    setAcceptTermsAndConditionsTouched(true);
  };

  const handleAcceptPrivacyPolicy = (event) => {
    setAcceptPrivacyPolicy(event.target.checked);
    setAcceptPrivacyPolicyTouched(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (data) => {
      setIsFormSubmitted(true);
    });
  };

  const fieldRequiredError = (
    <span className={`text-md text-red-700 mb-2 block`}>
      This field is required!
    </span>
  );

  return (
    <Section
      title={props.title ? props.title : defaultValues.title}
      contentBefore={props.content ? props.content : defaultValues.content}
      inverted={true}
    >
      <div className={`md:w-[70%] w-[95%] mx-auto`}>
        {!isFormSubmitted && (
          <ElementAnimation animation="fadeIn" delay={300}>
            <form onSubmit={onSubmit}>
              <div className={`pb-6`}>
                <label htmlFor="name" className={`secondary-font text-4xl`}>
                  Email
                </label>
                <input
                  id="name"
                  className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                  type="email"
                  name="EMAIL"
                  required
                  onChange={handleEmailChange}
                  onBlur={handleEmailChange}
                ></input>
                {emailTouched && emailIsEmpty && fieldRequiredError}
              </div>
              <div className={`mb-3`}>
                <label
                  htmlFor="acceptTermsAndConditions"
                  className={`secondary-font text-xl checkbox-container mb-0`}
                >
                  I accept the{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    className={`underline underline-offset-1`}
                  >
                    Terms & Conditions
                  </Link>{" "}
                  of this site
                  <input
                    id="acceptTermsAndConditions"
                    name="acceptTermsAndConditions"
                    type="checkbox"
                    checked={acceptTermsAndConditions}
                    onChange={handleAcceptTermsAndConditionsChange}
                    required
                  />
                  <span className="checkmark"></span>
                </label>
                {!acceptTermsAndConditions &&
                  acceptTermsAndConditionsTouched &&
                  fieldRequiredError}
              </div>
              <div className={`mb-4`}>
                <label
                  htmlFor="acceptPrivacyPolicy"
                  className={`secondary-font text-xl checkbox-container mb-0`}
                >
                  I accept the{" "}
                  <Link
                    to={"/privacy-policy"}
                    className={`underline underline-offset-1`}
                  >
                    Privacy Policy
                  </Link>{" "}
                  of this site
                  <input
                    className={`checkbox w-5 h-5`}
                    id="acceptPrivacyPolicy"
                    name="acceptPrivacyPolicy"
                    type="checkbox"
                    checked={acceptPrivacyPolicy}
                    onChange={handleAcceptPrivacyPolicy}
                    required
                  />
                  <span className="checkmark"></span>
                </label>
                {!acceptPrivacyPolicy &&
                  acceptPrivacyPolicyTouched &&
                  fieldRequiredError}
              </div>
              <button
                className={`${
                  !isFormValid ? "text-gray-500" : "text-black"
                } w-[100%] bg-white secondary-font text-4xl py-2`}
                type="submit"
              >
                Submit
              </button>
            </form>
          </ElementAnimation>
        )}
        {isFormSubmitted && (
          <span className={`secondary-font text-xl`}>
            Thanks for subscribing to our newsletter! You can unsubscribe
            anytime by the link in our newsletter email!
          </span>
        )}
      </div>
    </Section>
  );
};

export default MailChimpForm;
