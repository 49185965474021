import { gql } from "@apollo/client";

export const getGeneralSettings = gql`
  query generalSettings {
    generalSettings {
      data {
        id
        attributes {
          socialLinks {
            id
            email
            facebook
            instagram
            youtube
            soundcloud
          }
        }
      }
    }
  }
`;
