import { gql } from "@apollo/client";
import { textImgSectionFragment } from "./components/TextImgSection.gql";
import { eventsCarouselFragment } from "./components/EventsCarousel.gql";
import { artistsShowcaseFragment } from "./components/ArtistsShowcase.gql";
import { pageMetaDataFragment } from "./components/MetaData.gql";

export const getSinglePostInfo = gql`
  query getSinglePostInfo($url: String!) {
    post(url: $url) {
      data {
        id
        attributes {
          title
          url
          meta {
            ...pageMetaDataFragment
          }
          excerpt
          thumbnail {
            data {
              attributes {
                formats
              }
            }
          }
          content {
            __typename
            ...textImgSectionFragment
            ...eventsCarouselFragment
            ...artistsShowcaseFragment
          }
        }
      }
    }
  }
  ${textImgSectionFragment}
  ${eventsCarouselFragment}
  ${artistsShowcaseFragment}
  ${pageMetaDataFragment}
`;
