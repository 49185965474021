import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const DataRenderComponent = (props) => {
  const {
    component,
    gqlQuery,
    componentProps,
    pageSize = null,
    gqlVariables = {},
  } = props;
  const { url, pageNr } = useParams();

  const queryVariables = useMemo(
    () => ({
      ...gqlVariables,
      ...(url && { url }),
      ...(pageSize && {
        pagination: {
          page: pageNr ? Number(pageNr) : 1,
          pageSize: Number(pageSize),
        },
      }),
    }),
    [gqlVariables, url, pageSize, pageNr],
  );

  const { data, loading, error } = useQuery(gqlQuery, {
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
  });

  if (loading || error) {
    return <div className={`w-full h-[100vh]`}></div>;
  }

  const MemoizedComponent = React.memo(component);

  return <MemoizedComponent data={data} {...componentProps} />;
};

export default DataRenderComponent;
