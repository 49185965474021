import React from "react";

const components = {
  ComponentComponentsArtistsShowcase: React.lazy(
    () => import("../components/PageElements/ArtistsShowcase"),
  ),
  ComponentComponentsEventsCarousel: React.lazy(
    () => import("../components/PageElements/Events/EventsSlider"),
  ),
  ComponentComponentsPostsCarousel: React.lazy(
    () => import("../components/PageElements/Blog/ArticlesSlider"),
  ),
  ComponentComponentsTextImg: React.lazy(
    () => import("../components/PageElements/Utils/ContentSection"),
  ),
};

export default components;
