import Body from "./PageElements/Body";
import Footer from "./PageElements/Footer";
import Header from "./PageElements/Header";
import { Outlet } from "react-router-dom";
import RichSnippets from "./PageElements/RichSnippets";
import ScrollToTop from "./PageElements/Utils/ScrollToTop";
import { Suspense } from "react";

const Root = () => {
  return (
    <div className={`w-full min-h-screen`}>
      <Body>
        <ScrollToTop />
        <Header />
        <Suspense fallback={<></>}>
          <RichSnippets />
          <Outlet />
          <Footer />
        </Suspense>
      </Body>
    </div>
  );
};

export default Root;
