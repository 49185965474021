import { gql } from "@apollo/client";
import { postBasicInfoFragment } from "../Blog/InfoFragment.gql";

export const postsCarouselFragment = gql`
  fragment postsCarouselFragment on ComponentComponentsPostsCarousel {
    title
    content
    link {
      link
      linkTitle
    }
    postsData {
      ...postBasicInfoFragment
    }
  }
  ${postBasicInfoFragment}
`;
