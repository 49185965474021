import { gql } from "@apollo/client";
import { textImgSectionFragment } from "./components/TextImgSection.gql";
import { eventInfoFragment } from "./Events/InfoFragment.gql";
import { eventsPageSettingsFragment } from "./Events/PageSettingsFragment.gql";
import {
  collectionMetaDataFragment,
  pageMetaDataFragment,
} from "./components/MetaData.gql";

export const getUpcomingEventsInfo = gql`
  query events(
    $filters: EventFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    upcomingEvents: events(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
      data {
        id
        ...eventInfoFragment
      }
    }
  }
  ${eventInfoFragment}
`;

export const getEventsInfo = gql`
  query events(
    $upcomingFilters: EventFiltersInput
    $upcomingPagination: PaginationArg
    $upcomingSort: [String]
    $filters: EventFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    eventsListPage {
      data {
        attributes {
          ...eventsListPage
        }
      }
    }
    upcomingEvents: events(
      filters: $upcomingFilters
      pagination: $upcomingPagination
      sort: $upcomingSort
    ) {
      data {
        id
        ...eventInfoFragment
      }
    }
    pastEvents: events(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
      data {
        id
        ...eventInfoFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
  }
  ${eventInfoFragment}
  ${eventsPageSettingsFragment}
  ${collectionMetaDataFragment}
`;

export const getSingleEventInfo = gql`
  query event($url: String!) {
    event(url: $url) {
      data {
        id
        attributes {
          name
          url
          eventDate {
            id
            startDate
            endDate
          }
          location {
            id
            locationName
            locationLink
            address
            country
            region
            location
          }
          content {
            __typename
            ...textImgSectionFragment
          }
          artist {
            id
            name
            country
            mainLink
            description
            links
          }
          artists {
            data {
              id
              attributes {
                name
                image {
                  data {
                    attributes {
                      formats
                      alternativeText
                    }
                  }
                }
                url
                residentCountry
                link {
                  id
                  link
                  linkTitle
                }
                socialLinks {
                  id
                  email
                  facebook
                  instagram
                  youtube
                  soundcloud
                }
              }
            }
          }
          ticketsLink
          coverArtwork {
            data {
              attributes {
                formats
              }
            }
          }
          labels {
            id
            title
            color
          }
          relatedArticles {
            data {
              id
              attributes {
                title
                url
                thumbnail {
                  data {
                    attributes {
                      formats
                    }
                  }
                }
                excerpt
              }
            }
          }
          meta {
            ...pageMetaDataFragment
          }
          fbLink
          eventType
          selfHosted
          organizer
        }
      }
    }
  }
  ${textImgSectionFragment}
  ${pageMetaDataFragment}
`;
