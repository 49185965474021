import { Link } from "react-router-dom";

const Event = (props) => {
  const { name, image, date, location, locationLink, eventLink, labels } =
    props;

  return (
    <div
      className={`max-w-full w-full shadow-[10px_10px_0px_rgba(0,0,0,1)] appearance-none hover:shadow-[5px_5px_0px_rgba(0,0,0,1)] transition-all flex flex-col border-2 border-black`}
    >
      <div
        className={`relative aspect-[1.91/1] overflow-hidden flex items-center bg-white`}
      >
        <Link className={`w-full`} to={`/event/` + eventLink}>
          <img
            loading="lazy"
            src={image}
            alt={name}
            width="600px"
            height="262px"
            className={`w-full`}
          />
        </Link>
        <div className={`absolute right-0 top-0`}>
          {labels.map((label) => (
            <div
              className={`inline-block px-2 text-white ml-2`}
              style={{ backgroundColor: label.color }}
              key={label.title}
            >
              <span className={`secondary-font`}>{label.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`p-2 relative bg-white md:flex-grow flex flex-col md:justify-between`}
      >
        <Link to={`/event/` + eventLink}>
          <h3 className={`sm:text-2xl text-1xl secondary-font md:mb-0 mb-2`}>
            {name}
          </h3>
        </Link>
        <ul className={`text-sm md:mt-auto mt-0`}>
          <li className={`pt-1`}>
            <i
              className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
            />
            {new Date(date).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </li>
          <li className={`pt-1`}>
            <i
              className={`location-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
            />
            {locationLink ? (
              <Link to={locationLink} target="_blank" aria-label={location}>
                {location}
              </Link>
            ) : (
              location
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Event;
