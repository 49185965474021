import Section from "../PageElements/Section";
import ArtistSocials from "../PageElements/Artist/ArtistSocials";
import LinkButton from "../PageElements/Utils/LinkButton";
import EventsSlider from "../PageElements/Events/EventsSlider";
import ArtistsShowcase from "../PageElements/ArtistsShowcase";
import SetsList from "../PageElements/MusicFeed/SetsList";
import BookingForm from "../PageElements/Artist/BookingForm";
import Releases from "../PageElements/Artist/Releases";
import MetaData from "../PageElements/Utils/MetaData";
import config from "../../config/site";
import { Link } from "react-router-dom";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";
import WebPage from "../PageElements/RichSnippet/WebPage";
import React from "react";
import Member from "../PageElements/RichSnippet/Member";

const ArtistDetailPage = (props) => {
  const { data } = props;
  const artistData = data?.artist?.data;

  return (
    <>
      {artistData && (
        <MetaData
          title={artistData?.attributes?.name}
          keywords={artistData?.attributes?.meta?.metaKeywords}
          description={artistData?.attributes?.meta?.metaDescription}
          image={
            config.backend_url +
            artistData.attributes.image.data.attributes.formats.medium.url
          }
        />
      )}
      {artistData && (
        <>
          <WebPage
            mainEntityId={
              window.location.origin + "#Person" + artistData?.attributes?.url
            }
            title={artistData?.attributes?.name}
            description={artistData?.attributes?.meta?.metaDescription}
          />
          <Member artistData={artistData} />
        </>
      )}
      {artistData && (
        <div>
          <Section withPadding={false}>
            <div className={`grid md:grid-cols-2 grid-cols-1`}>
              <div>
                <Section
                  className={`md:p-8 md:pr-4`}
                  title={artistData.attributes.name}
                  isMainTitle={true}
                  inverted={true}
                  isSecondaryFont={true}
                >
                  <div className={`grid grid-cols-6`}>
                    <ElementAnimation
                      className={`col-span-5`}
                      animation="fadeIn"
                      delay={300}
                    >
                      <img
                        src={
                          config.backend_url +
                          artistData.attributes.image.data.attributes.formats
                            .medium.url
                        }
                        alt={artistData.attributes.name}
                      />
                    </ElementAnimation>
                    <ArtistSocials
                      className={`col-span-1 m-auto`}
                      socialLinks={artistData.attributes.socialLinks}
                    />
                  </div>
                  <div className={`text-center secondary-font text-sm`}>
                    <ElementAnimation animation="fadeInUp" delay={400}>
                      <Link to={artistData.attributes.pressKit}>
                        Download Press Kit
                      </Link>
                    </ElementAnimation>
                  </div>
                </Section>
              </div>
              <div>
                <Section
                  className={`text-left md:p-8`}
                  textNotCentered={true}
                  title={"Biography"}
                  contentBefore={artistData.attributes.biography}
                />
              </div>
            </div>
          </Section>
          {artistData.attributes.link !== undefined &&
            artistData.attributes.link.length > 0 && (
              <Section className={`text-left md:p-8`} title={"Links"}>
                {artistData.attributes.link.map((link, index) => (
                  <ElementAnimation
                    animation="fadeInUp"
                    delay={300 + index * 50}
                    key={index}
                  >
                    <div key={link.link}>
                      <a href={link.link} target="_blank">
                        <span className={`secondary-font sm:text-5xl text-2xl`}>
                          {link.linkTitle}
                        </span>
                      </a>
                    </div>
                  </ElementAnimation>
                ))}
              </Section>
            )}
          {artistData?.attributes?.music_feed_sets?.data && (
            <Section
              title={"Latest Sets"}
              contentBefore={
                "Check out my latest uploaded sets and streams below!"
              }
              inverted={true}
            >
              <SetsList
                musicFeedData={artistData.attributes.music_feed_sets.data}
              />
            </Section>
          )}
          {artistData.attributes.releases !== undefined &&
            artistData.attributes.releases.data !== undefined &&
            artistData.attributes.releases.data.length > 0 && (
              <Section
                title={"Releases"}
                contentBefore={"Check out my latest releases below!"}
              >
                <Releases releaseData={artistData.attributes.releases.data} />
              </Section>
            )}
          <Section
            title={"Interested?"}
            contentBefore={
              "If you’re interested in booking me, find the form by clicking on the button below!"
            }
            inverted={true}
          >
            <LinkButton link={"#booking"} title={"Booking"} inverted={true} />
          </Section>
          {artistData.attributes.clubsPlayed &&
            artistData.attributes.clubsPlayed.length > 0 && (
              <Section title={"Clubs Played"}>
                {artistData.attributes.clubsPlayed.map((club, index) => (
                  <div key={club.clubName}>
                    <ElementAnimation
                      animation="fadeInUp"
                      delay={300 + index * 50}
                    >
                      {club.clubLink ? (
                        <a
                          href={club.clubLink}
                          aria-label={club.clubName}
                          target="_blank"
                        >
                          <span
                            className={`secondary-font sm:text-5xl text-2xl`}
                          >
                            {club.clubName} / {club.clubCountry}
                          </span>
                        </a>
                      ) : (
                        <span className={`secondary-font sm:text-5xl text-2xl`}>
                          {club.clubName} / {club.clubCountry}
                        </span>
                      )}
                    </ElementAnimation>
                  </div>
                ))}
              </Section>
            )}
          {artistData.attributes.lineupsPlayed &&
            artistData.attributes.lineupsPlayed.length > 0 && (
              <Section
                title={"Lineups"}
                contentBefore={"Some of the notable artists I’ve played with:"}
                inverted={true}
              >
                {artistData.attributes.lineupsPlayed.map((lineup, index) => (
                  <div key={lineup.artistName}>
                    <ElementAnimation
                      animation="fadeInUp"
                      delay={300 + index * 50}
                    >
                      {lineup.artistLink ? (
                        <a
                          href={lineup.artistLink}
                          aria-label={lineup.artistName}
                          target="_blank"
                        >
                          <span
                            className={`secondary-font sm:text-5xl text-2xl`}
                          >
                            {lineup.artistName} / {lineup.artistCountry}
                          </span>
                        </a>
                      ) : (
                        <span className={`secondary-font sm:text-5xl text-2xl`}>
                          {lineup.artistName} / {lineup.artistCountry}
                        </span>
                      )}
                    </ElementAnimation>
                  </div>
                ))}
              </Section>
            )}
          {artistData?.attributes?.events?.data && (
            <Section title={"Events"} inverted={false}>
              <EventsSlider
                data={{
                  eventsData: artistData?.attributes?.events?.data,
                }}
              />
            </Section>
          )}
          <Section
            title="Booking"
            contentBefore="Book our artist on the form below!<br/>After you sent the form, he’ll contact you ASAP to discuss the details!"
            inverted={true}
            id={`booking`}
          >
            <BookingForm />
          </Section>
          <ArtistsShowcase />
        </div>
      )}
    </>
  );
};

export default ArtistDetailPage;
