import Section from "../PageElements/Section";
import { Link } from "react-router-dom";
import LinkButton from "../PageElements/Utils/LinkButton";
import ContactUsButton from "../PageElements/Utils/ContactUsButton";
import MailChimpForm from "../PageElements/MailChimp/MailChimpForm";
import MetaData from "../PageElements/Utils/MetaData";
import config from "../../config/site";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";
import Event from "../PageElements/RichSnippet/Event";
import WebPage from "../PageElements/RichSnippet/WebPage";
import React from "react";
import renderDynamicComponents from "../../util/dynamicComponentsRenderer";

const EventDetailPage = (props) => {
  const { data } = props;
  const eventData = data?.event?.data;

  return (
    <>
      {eventData && (
        <MetaData
          title={eventData?.attributes?.name}
          keywords={eventData?.attributes?.meta?.metaKeywords}
          description={eventData?.attributes?.meta?.metaDescription}
          image={
            config.backend_url +
            eventData.attributes.coverArtwork.data.attributes.formats.medium.url
          }
        />
      )}
      {eventData && (
        <>
          <WebPage
            mainEntityId={
              window.location.origin + "#Event" + eventData?.attributes?.url
            }
            title={eventData?.attributes?.name}
            description={eventData?.attributes?.content}
          />
          <Event
            eventsData={eventData?.attributes}
            key={eventData?.attributes?.id}
          />
        </>
      )}
      {eventData && (
        <div>
          <Section withPadding={false}>
            <div className={`grid md:grid-cols-2 grid-cols-1`}>
              <div className={`w-full md:min-h-[270px] min-h-[200px]`}>
                <ElementAnimation
                  animation="fadeIn"
                  delay={0}
                  className={`relative w-full h-full overflow-hidden`}
                >
                  <img
                    className={`w-auto h-full object-cover`}
                    width={
                      eventData.attributes.coverArtwork.data?.attributes
                        ?.formats.medium.width
                    }
                    height={
                      eventData.attributes.coverArtwork.data?.attributes
                        ?.formats.medium.height
                    }
                    src={
                      config.backend_url +
                      eventData.attributes.coverArtwork.data.attributes.formats
                        .medium.url
                    }
                    alt={eventData.attributes.name}
                  />
                </ElementAnimation>
              </div>
              <div>
                <Section
                  className={`text-left md:px-8 px-4`}
                  textNotCentered={true}
                  title={eventData.attributes.name}
                  isSecondaryFont={true}
                  titleSm={true}
                  withPadding={false}
                >
                  <div
                    className={`md:block sm:flex block justify-between relative`}
                  >
                    <div className={`md:block`}>
                      <ul>
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={0}
                          >
                            <i
                              className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            <span>
                              {new Date(
                                eventData.attributes.eventDate.startDate,
                              ).toLocaleTimeString("en-us", {
                                weekday: "short",
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </ElementAnimation>
                        </li>
                        {eventData.attributes.eventDate.endDate && (
                          <li>
                            <ElementAnimation
                              className={`inline-block`}
                              animation="fadeInUp"
                              delay={50}
                            >
                              <i
                                className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                              />
                              <span>
                                {new Date(
                                  eventData.attributes.eventDate.endDate,
                                ).toLocaleTimeString("en-us", {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </ElementAnimation>
                          </li>
                        )}
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={100}
                          >
                            <i
                              className={`location-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            {eventData.attributes.location.locationLink ? (
                              <Link
                                to={eventData.attributes.location.locationLink}
                                target="_blank"
                                aria-label={
                                  eventData.attributes.location.locationName
                                }
                              >
                                {eventData.attributes.location.locationName}
                              </Link>
                            ) : (
                              eventData.attributes.location.locationName
                            )}
                          </ElementAnimation>
                        </li>
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={150}
                          >
                            <i
                              className={`fb-black-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            <Link
                              to={eventData.attributes.fbLink}
                              target="_blank"
                              aria-label="Facebook Event"
                            >
                              Facebook Event
                            </Link>
                          </ElementAnimation>
                        </li>
                      </ul>
                    </div>
                    {eventData.attributes.ticketsLink && (
                      <ElementAnimation
                        className={`block`}
                        animation="fadeIn"
                        delay={200}
                      >
                        <div
                          className={`w-auto md:mt-6 mb-3 sm:mt-0 mt-4 min-w-[250px]`}
                        >
                          <LinkButton
                            title="Tickets"
                            link={eventData.attributes.ticketsLink}
                            centered={false}
                            targetBlank={true}
                            className={`md:mb-0 my-auto block`}
                          />
                        </div>
                      </ElementAnimation>
                    )}
                  </div>
                </Section>
              </div>
            </div>
          </Section>
          {(eventData.attributes.artist.length > 0 ||
            eventData.attributes.artists.data.length > 0) && (
            <Section title={"Artists"} inverted={true}>
              <ElementAnimation
                className={`block`}
                animation="fadeInUp"
                delay={100}
              >
                {eventData.attributes.artist.map((artist) => (
                  <div className={`inline-block`} key={artist.name}>
                    {artist.link ? (
                      <a className={`pr-10`} href={artist.link} target="_blank">
                        <span className={`secondary-font sm:text-5xl text-3xl`}>
                          {artist.name} / {artist.country}
                        </span>
                      </a>
                    ) : (
                      <span
                        className={`secondary-font sm:text-5xl text-3xl pr-10`}
                      >
                        {artist.name} / {artist.country}
                      </span>
                    )}
                  </div>
                ))}
                {eventData.attributes.artists.data.map((artist) => (
                  <div className={`inline-block`} key={artist.attributes.name}>
                    <Link
                      className={`pr-10`}
                      to={"/artist/" + artist.attributes.url}
                    >
                      <span className={`secondary-font sm:text-5xl text-3xl`}>
                        {artist.attributes.name +
                          " / " +
                          artist.attributes.residentCountry}
                      </span>
                    </Link>
                  </div>
                ))}
              </ElementAnimation>
            </Section>
          )}
          {eventData?.attributes?.content &&
            renderDynamicComponents(eventData.attributes.content)}
          <MailChimpForm />
          <ContactUsButton />
        </div>
      )}
    </>
  );
};

export default EventDetailPage;
