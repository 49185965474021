import { gql } from "@apollo/client";
import { artistsBasicInfoFragment } from "./Artists/InfoFragment.gql";
import { eventInfoFragment } from "./Events/InfoFragment.gql";
import { pageMetaDataFragment } from "./components/MetaData.gql";
import { musicFeedSetsFragment } from "./MusicFeedSets.gql";

export const getArtistsBasicInfo = gql`
  query getArtistsBasicInfo {
    artists {
      data {
        id
        ...artistsBasicInfoFragment
      }
    }
  }
  ${artistsBasicInfoFragment}
`;

export const getSingleArtistInfo = gql`
  query artist($url: String!, $pagination: PaginationArg, $sort: [String]) {
    artist(url: $url) {
      data {
        id
        attributes {
          name
          biography
          image {
            data {
              attributes {
                formats
                alternativeText
              }
            }
          }
          link {
            id
            link
            linkTitle
          }
          url
          socialLinks {
            id
            email
            facebook
            instagram
            youtube
            soundcloud
          }
          clubsPlayed {
            id
            clubName
            clubCountry
            clubLink
          }
          lineupsPlayed {
            id
            artistName
            artistCountry
            artistLink
          }
          metaData {
            ...pageMetaDataFragment
          }
          jobTitle
          pressKit
          residentCountry
          music_feed_sets {
            data {
              ...musicFeedSetsFragment
            }
          }
          releases {
            data {
              id
              attributes {
                title
                link
                createdAt
                updatedAt
                artwork {
                  data {
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          events(pagination: $pagination, sort: $sort) {
            data {
              ...eventInfoFragment
            }
          }
        }
      }
    }
  }
  ${eventInfoFragment}
  ${pageMetaDataFragment}
  ${musicFeedSetsFragment}
`;
