import { gql } from "@apollo/client";
import {
  collectionMetaDataFragment,
  pageMetaDataFragment,
} from "./components/MetaData.gql";
import { textImgSectionFragment } from "./components/TextImgSection.gql";
import { postsCarouselFragment } from "./components/PostsCarousel.gql";
import { eventsCarouselFragment } from "./components/EventsCarousel.gql";
import { artistsShowcaseFragment } from "./components/ArtistsShowcase.gql";

export const musicFeedSetsFragment = gql`
  fragment musicFeedSetsFragment on MusicFeedSetEntity {
    id
    attributes {
      title
      madeBy
      link
      platform
      createdAt
      artwork {
        data {
          attributes {
            formats
            alternativeText
          }
        }
      }
    }
  }
`;

export const getPreviousAndNextSets = gql`
  query musicFeedSets(
    $prevFilters: MusicFeedSetFiltersInput
    $prevPagination: PaginationArg
    $prevSort: [String]
    $nextFilters: MusicFeedSetFiltersInput
    $nextPagination: PaginationArg
    $nextSort: [String]
  ) {
    previous: musicFeedSets(
      filters: $prevFilters
      pagination: $prevPagination
      sort: $prevSort
    ) {
      data {
        ...musicFeedSetsFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
    next: musicFeedSets(
      filters: $nextFilters
      pagination: $nextPagination
      sort: $nextSort
    ) {
      data {
        ...musicFeedSetsFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
  }
  ${collectionMetaDataFragment}
  ${musicFeedSetsFragment}
`;

export const getMusicFeedSets = gql`
  query musicFeedSets(
    $filters: MusicFeedSetFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    musicFeedSets(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        ...musicFeedSetsFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
  }
  ${collectionMetaDataFragment}
  ${musicFeedSetsFragment}
`;

export const getMusicFeedPageSettings = gql`
  query musicFeedPage(
    $filters: MusicFeedSetFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    musicFeedPage {
      data {
        attributes {
          meta {
            ...pageMetaDataFragment
          }
          title
          content
          afterListContent {
            ...textImgSectionFragment
            ...postsCarouselFragment
            ...eventsCarouselFragment
            ...artistsShowcaseFragment
          }
        }
      }
    }
    musicFeedSets(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        ...musicFeedSetsFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
  }
  ${collectionMetaDataFragment}
  ${pageMetaDataFragment}
  ${textImgSectionFragment}
  ${postsCarouselFragment}
  ${eventsCarouselFragment}
  ${artistsShowcaseFragment}
  ${musicFeedSetsFragment}
`;
