import components from "../config/componentsTypemap";
import React from "react";

const renderDynamicComponents = (data) => {
  return data?.map((element, index) =>
    typeof components[element["__typename"]] != "undefined"
      ? React.createElement(components[element["__typename"]], {
          key: index,
          data: element,
        })
      : "",
  );
};

export default renderDynamicComponents;
