import { gql } from "@apollo/client";
import { postBasicInfoFragment } from "./InfoFragment.gql";
import {
  collectionMetaDataFragment,
  pageMetaDataFragment,
} from "../components/MetaData.gql";

export const getBlogPageSettings = gql`
  query blogPage(
    $filters: PostFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    blogPage {
      data {
        attributes {
          title
          meta {
            ...pageMetaDataFragment
          }
          content
        }
      }
    }
    posts(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        ...postBasicInfoFragment
      }
      meta {
        ...collectionMetaDataFragment
      }
    }
  }
  ${postBasicInfoFragment}
  ${collectionMetaDataFragment}
  ${pageMetaDataFragment}
`;
