import { gql } from "@apollo/client";

export const textImgSectionFragment = gql`
  fragment textImgSectionFragment on ComponentComponentsTextImg {
    title
    image {
      data {
        attributes {
          formats
          alternativeText
        }
      }
    }
    imgAltText
    imgRight
    text
    invertedColor
    link {
      link
      linkTitle
    }
    linkAsButton
    isSecondaryFont
    isMainTitle
  }
`;
