import Event from "./Event";

const Events = (props) => {
  const { eventsData, addItemList = false } = props;

  let itemListJson = addItemList
    ? {
        "@context": "https://schema.org",
        "@id": window.location.href + "#EventsList",
        "@type": "ItemList",
        itemListElement: [],
      }
    : null;

  if (eventsData && addItemList) {
    eventsData.map((event, index) => {
      itemListJson.itemListElement.push({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id": window.location.origin + "#Event" + event?.attributes?.url,
        },
      });
    });
  }

  return (
    <>
      {addItemList && (
        <script type="application/ld+json">
          {JSON.stringify(itemListJson)}
        </script>
      )}
      {eventsData &&
        eventsData.map((event) => {
          return <Event eventsData={event?.attributes} key={event.id} />;
        })}
    </>
  );
};

export default Events;
